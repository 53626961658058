import {Box, Button, Divider, Grid, InputAdornment, TextField, Typography, useTheme} from '@mui/material';
import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import SearchIcon from "@mui/icons-material/Search";
import ImpersonateAccountContactTable from "./ImpersonateAccountContactTable";
import useAccountApi, {Account, AccountContact} from "../../../hooks/account/Account";
import ImpersonateAccountTable from './ImpersonateAccountTable';
import {Link} from "react-router-dom";
import {ImpersonateUserContext} from "../../../providers/ImpersonateUserProvider";
import {UserAccountContext} from "../../../providers/UserAccountProvider";
import LoadingSpinner from "../../../components/loadingspinner/LoadingSpinner";

export default function ImpersonateUserInformationPage() {

    const {user} = useContext(UserAccountContext)
    const {setImpersonateUsername, setImpersonateId,} = useContext(ImpersonateUserContext)

    const {t} = useTranslation();
    const theme = useTheme();
    const {getAccountContacts, getPaginatedAccounts} = useAccountApi();

    const [loadingAccounts, setLoadingAccounts] = useState<boolean>(true);
    const [loadingContacts, setLoadingContacts] = useState<boolean>(false);
    const [contactFilter, setContactFilter] = React.useState<string | undefined>(undefined);
    const [accountFilter, setAccountFilter] = React.useState<string | undefined>(undefined);
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [selectedAccountRow, setSelectedAccountRow] = useState<number | undefined>();
    const [contacts, setContacts] = useState<AccountContact[]>([]);
    const [selectedContactRow, setSelectedContactRow] = useState<number | undefined>();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState(0);
    const [search, setSearch] = useState<string>("");
    React.useEffect(() => {
        async function getAccountContactList() {
            setLoadingContacts(true)
            if (selectedAccountRow) {
                let contacts = await getAccountContacts(selectedAccountRow);
                if (!contacts) {
                    setContacts([])
                } else {
                    // current user cannot impersonate himself
                    setContacts(contacts.filter(contact => contact.id.toString() !== user?.id))
                }
            }
            setLoadingContacts(false)
        }

        getAccountContactList()
        setSelectedContactRow(undefined)
    }, [selectedAccountRow]);

    React.useEffect(() => {
        async function getAccountList() {
            setLoadingAccounts(true)
            let accounts = await getPaginatedAccounts(currentPage, 10, search);
            if (!accounts) {
                setAccounts([])
            } else {
                setAccounts(accounts.accounts)
                setTotalElements(accounts.totalElements)
            }
            setLoadingAccounts(false)
        }

        getAccountList()
    }, [currentPage, search]);

    const handleChangePage = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const onContactSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setContactFilter(undefined);
        } else {
            setContactFilter(search);
        }
    }

    const onAccountSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setAccountFilter(undefined);
            setSearch('');
        } else {
            setAccountFilter(search);
            setSearch(search);
            setContacts([]);
            setCurrentPage(0);
        }
    }

    const handleClearSelection = () => {
        setSelectedContactRow(undefined);
        setSelectedAccountRow(undefined);
        setAccountFilter("");
        setContactFilter("");
        setContacts([]);
        setSearch("");
    };

    const handleImpersonateLogin = async () => {
        let contact = contacts.find(contact => contact.id === selectedContactRow);
        if (contact) {
            setImpersonateId(contact.id)
            setImpersonateUsername(contact.username)
        } else {
            setImpersonateId(undefined)
            setImpersonateUsername(undefined)
        }
    }

    const filteredContacts = (accountContacts: Array<AccountContact>) => {
        let filteredContacts = accountContacts

        if (contactFilter) {
            let lowerCaseFilter = contactFilter.toLowerCase();
            filteredContacts = accountContacts.filter(accountContact => {
                if (accountContact) {
                    // Check for account properties and filter accordingly
                    if (accountContact.email.toLowerCase().includes(lowerCaseFilter) ||
                        accountContact.username.toLowerCase().includes(lowerCaseFilter)) {
                        return true;
                    }
                }
                return false; // Return false for account contact that don't match the filter
            });
        }

        return filteredContacts;
    }

    const filteredAccounts = (accounts: Array<Account>) => {
        let filteredAccounts = accounts

        if (accountFilter) {
            let lowerCaseFilter = accountFilter.toLowerCase();
            filteredAccounts = accounts.filter(account => {
                if (account) {
                    // Check for account properties and filter accordingly
                    if (account.name.toLowerCase().includes(lowerCaseFilter) ||
                        account.salesforceId.toLowerCase().includes(lowerCaseFilter)) {
                        return true;
                    }
                }
                return false; // Return false for account contact that don't match the filter
            });
        }

        return filteredAccounts;
    }


    return (
        <Box sx={{
            display: 'flex', flexDirection: "column", flexGrow: 1, justifyContent: "space-between"
        }}>
            <Box sx={{
                padding: '30px',
                paddingTop: '10px',
            }}>
                <Typography variant="header" sx={{paddingBottom: "30px"}}>
                    {t('impersonate_page.title')}
                </Typography>

                <Divider/>

                <Grid container padding="10px" spacing={2.5}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Typography variant="title"> {t('impersonate_page.account_information')} </Typography>
                        <Typography variant="body"> {t('impersonate_page.account_description')} </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                        <Box sx={{
                            paddingBottom: '10px'
                        }}>
                            <TextField
                                fullWidth
                                id="impersonate-account-search"
                                size="small"
                                label={t("impersonate_page.account_search_box_label")}
                                variant="outlined"
                                color="info"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                value={accountFilter}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    onAccountSearchChange(event.target.value);
                                }}
                            />
                        </Box>
                        <Grid>
                            {loadingAccounts && <LoadingSpinner/>}
                            {!loadingAccounts && <ImpersonateAccountTable accounts={filteredAccounts(accounts)}
                                                                          currentPage={currentPage}
                                                                          totalElements={totalElements}
                                                                          handleChangePage={handleChangePage}
                                                                          selectedRow={selectedAccountRow}
                                                                          setSelectedRow={setSelectedAccountRow}
                            />}
                        </Grid>

                    </Grid>

                </Grid>

                <Divider/>

                <Grid container padding="10px" spacing={2.5}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Typography variant="title"> {t('impersonate_page.user_information')} </Typography>
                        <Typography variant="body"> {t('impersonate_page.user_description')} </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                        <Box sx={{
                            paddingBottom: '10px'
                        }}>
                            <TextField
                                fullWidth
                                id="impersonate-contact-search"
                                size="small"
                                label={t("impersonate_page.account_contact_search_box_label")}
                                variant="outlined"
                                color="info"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                value={contactFilter}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    onContactSearchChange(event.target.value);
                                }}
                            />
                        </Box>
                        <Grid>
                            {loadingContacts && <LoadingSpinner/>}
                            {!loadingContacts &&
                                <ImpersonateAccountContactTable accountContacts={filteredContacts(contacts)}
                                                            selectedRow={selectedContactRow}
                                                            setSelectedRow={setSelectedContactRow}
                            />}
                        </Grid>

                    </Grid>

                </Grid>

            </Box>

            <Box
                bottom="0px"
                sx={{
                    padding: '10px',
                    borderTop: '1px solid ' + theme.palette.contentPage.footerDivider,
                    display: 'flex', flexDirection: "row", justifyContent: "space-between"
                }}>
                <Box/>

                <Grid container paddingLeft="20px" paddingRight="20px">
                    <Grid item xs={6} paddingTop="5px" paddingBottom="5px">
                        <Box
                            bottom="0px"
                            sx={{
                                padding: '10px',
                                display: 'flex', flexDirection: "row", justifyContent: "space-between",
                            }}>
                            <Button
                                variant="contained"
                                size='small'
                                color="secondary"
                                onClick={handleClearSelection}
                            >
                                {t("button.clear_selection")}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={6} paddingTop="5px" paddingBottom="5px"
                          sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Box
                            bottom="0px"
                            sx={{
                                padding: '10px',
                                display: 'flex', flexDirection: "row", justifyContent: "space-between"
                            }}>
                            <Button
                                variant="contained" size='small' color="primary"
                                component={Link}
                                to="/"
                                disabled={selectedContactRow === undefined}
                                onClick={handleImpersonateLogin}
                            >
                                {t('button.impersonate')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );


};





