//Icons
import RoundaboutRightIcon from "@mui/icons-material/RoundaboutRight";
import MapIcon from "@mui/icons-material/Map";
import HomeIcon from '@mui/icons-material/Home';
import TimelineIcon from '@mui/icons-material/Timeline';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AssessmentIcon from '@mui/icons-material/Assessment';
import NavigationIcon from '@mui/icons-material/Navigation';

//MUI Component imports
import {useTheme} from "@mui/material/styles";

import List from "@mui/material/List";

//RAM Component imports
import DrawListItem from "../../components/appdrawer/DrawListItem";


import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import PropertiesLoader from "../../properties/PropertiesLoader";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LabelIcon from '@mui/icons-material/Label';

interface NavigationListProps {
    openDrawer: boolean;
}

const properties = PropertiesLoader();

function NavigationList(props: NavigationListProps) {
    const {t} = useTranslation();
    const {pathname} = useLocation()
    const theme = useTheme();

    return (
        <List
            dense
            sx={{
                height: "100%",
                backgroundColor: theme.palette.drawer.background,
                padding: "10px"
            }}
        >
            <DrawListItem
                itemName="Home"
                text={t("drawer.home")}
                icon={<HomeIcon/>}
                to={".."}
                onClick={() => {
                }}
                drawOpen={props.openDrawer}
                selected={pathname === '/'}
            />
            <DrawListItem
                itemName="Live Map"
                text={t("drawer.live_map")}
                icon={<MapIcon/>}
                to={"live-map"}
                onClick={() => {
                }}
                drawOpen={props.openDrawer}
                selected={pathname === '/live-map'}
            />
            {properties.featureFlags.assetManagement && (
                <>
                    <DrawListItem
                        itemName="Assets"
                        text={t("drawer.assets")}
                        icon={<DirectionsCarIcon/>}
                        to={"assets"}
                        onClick={() => {
                        }}
                        drawOpen={props.openDrawer}
                        selected={pathname === '/assets'}
                    />
                    <DrawListItem
                        itemName="Asset Groups"
                        text={t("drawer.asset_groups")}
                        icon={<LabelIcon/>}
                        to={"asset-groups"}
                        onClick={() => {
                        }}
                        drawOpen={props.openDrawer}
                        selected={pathname === '/asset-groups'}
                    />
                </>
            )}
            <DrawListItem
                itemName="Route Replay"
                text={t("drawer.route_replay")}
                icon={<RoundaboutRightIcon/>}
                to={"route-replay"}
                onClick={() => {
                }}
                drawOpen={props.openDrawer}
                selected={pathname === '/route-replay'}
            />
            <DrawListItem
                itemName="Timeline"
                text={t("drawer.timeline")}
                icon={<TimelineIcon/>}
                to={"timeline"}
                onClick={() => {
                }}
                drawOpen={props.openDrawer}
                selected={pathname === '/timeline'}
            />
            <DrawListItem
                itemName="Geofences"
                text={t("drawer.geofences")}
                icon={<LocationOnOutlinedIcon/>}
                to={"geofences"}
                onClick={() => {
                }}
                drawOpen={props.openDrawer}
                selected={pathname === '/geofences'}
            />
            <DrawListItem
                itemName="Alerts"
                text={t("drawer.alerts")}
                icon={<WarningAmberIcon/>}
                to={"alerts"}
                onClick={() => {
                }}
                drawOpen={props.openDrawer}
                selected={pathname === '/alerts'}
            />
            <DrawListItem
                itemName="Reports"
                text={t("drawer.reports")}
                icon={<AssessmentIcon/>}
                to={"reports"}
                onClick={() => {
                }}
                drawOpen={props.openDrawer}
                selected={pathname === '/reports'}
            />
            <DrawListItem
                itemName="Live Share"
                text={t("drawer.liveshare")}
                icon={<NavigationIcon/>}
                to={"liveshare"}
                onClick={() => {
                }}
                drawOpen={props.openDrawer}
                selected={pathname === '/liveshare'}
            />

        </List>);
}

export default NavigationList;
