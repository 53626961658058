
import { Divider, ListItemButton, Box, Typography } from '@mui/material';
import { Asset } from '../../hooks/assets/dto/Asset';
import PersonIcon from '@mui/icons-material/Person';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NearMeIcon from '@mui/icons-material/NearMe';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { buildAssetStatusMessage } from '../../utils/AssetUtils';
import { Map } from 'leaflet';
import { AssetCardIconText } from './AssetCardIconText';
import { AssetWarnMessage } from './AssetWarnMessage';
import { useTranslation } from 'react-i18next';
import {useContext} from "react";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
interface AssetCardProps {
  mapref: React.RefObject<Map>;
  asset: Asset;
  clickedVehicle: Function
}

function AssetCard(props: AssetCardProps) {
  const { t } = useTranslation();
  const {twentyFourHourEnabled} = useContext(UserPreferencesContext)

  const handleListItemClick = (
    asset: Asset,
  ) => {
    props.clickedVehicle(asset)
    if (props.mapref.current !== null && asset.latestEvent && asset.latestEvent.location) {
      props.mapref.current.setView([asset.latestEvent.location.rawCoordinates.latitude, asset.latestEvent.location.rawCoordinates.longitude], 18);
    }
  };

  const geofenceName = props.asset.latestEvent && props.asset.latestEvent.location.geofences[0] ? props.asset.latestEvent.location.geofences[0].geofenceName + ", " : undefined;

  return (
    <>
      <ListItemButton alignItems="flex-start" onClick={(event) => handleListItemClick(props.asset)}>
        <Box>
          <Typography
            sx={{ display: "block", width: "100%" }}
            component="div"
            variant="cardtitle"
            color="text.primary"
          >
            {props.asset.alias || props.asset.identificationMark}
          </Typography>
          {props.asset.latestEvent?.location.goodGpsFix === false && <AssetWarnMessage text={t('asset_messages.poor_gps_fix') as String} />}
          <AssetCardIconText icon={<LocationOnIcon fontSize="inherit" />} prefixValue={geofenceName} value={props.asset.latestEvent?.location.formattedAddress || ""} />
          <AssetCardIconText icon={<NearMeIcon fontSize="inherit" />} value={buildAssetStatusMessage(props.asset)} />
          <AssetCardIconText icon={<PersonIcon fontSize="inherit" />} value={props.asset.assignee.name} />
          {props.asset.groupName !== null && <AssetCardIconText icon={<WorkspacesIcon fontSize="inherit" />} value={props.asset.groupName!} />}
          <AssetCardIconText icon={<AccessTimeFilledIcon fontSize="inherit" />} value={props.asset.latestEvent?.time.toLocaleString('en-GB', {year: 'numeric', month: "numeric", day: 'numeric', hour: "numeric", minute: "numeric", second: "numeric",  hour12: !twentyFourHourEnabled}).toUpperCase() || ""} />
        </Box>
      </ListItemButton>
      <Divider component="li" />
    </>
  );
}

export default AssetCard;
