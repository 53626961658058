import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextInput from "../../../components/form/TextInput";
import { SelectorLabel } from "./SelectorLabel";

interface ReportEmailProps {
    email: string;
    setEmail: (notes: string) => void;
}

export function ReportEmailSelector(props: ReportEmailProps) {

    const { t } = useTranslation();

    return <Grid container>
    <SelectorLabel
        title={t("report.email")}
        subtitle={t("report.enter_email")}
        />
                
        <Grid item xs={12} md={8} lg={10} padding="10px">
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={10} padding="0px">
                    <TextInput editMode={true} isEmail={true}
                                label={''} required={false} placeholder={''}
                                value={props.email} onChange={props.setEmail}/>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}