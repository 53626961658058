import { Box, Button, Divider, Grid, InputAdornment, Paper, TextField, Typography, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";

import React, { useContext, useState } from "react";
import { FilterSortContext } from "../../../providers/FilterSortProvider";
import { Asset } from "../../../hooks/assets/dto/Asset";
import { AssetSortOptions, sortByDriverName, sortByLastEventTime } from "../../../utils/AssetArraySorting";
import { VehicleEvent } from "../../../enum/VehicleEvent";
import EventTypeSelection from "./components/EventTypeSelection";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from "dayjs";
import { useAssetApi } from "../../../hooks/assets/Assets";
import { useNavigate } from "react-router-dom";
import useAccountApi, { AccountContact } from "../../../hooks/account/Account";
import { EventAlert, useEventAlertsApi } from "../../../hooks/alerts/EventAlerts";
import { filterAssetsBySearch } from "../../../utils/AssetArrayFiltering";
import SelectAssetTable from "../../../components/table/shared/SelectAssetTable";
import SelectRecipientTable from "../../../components/table/shared/SelectRecipientTable";
import SelectStringTable from "../../../components/table/shared/SelectStringTable";

export default function CreateEventAlertsPage() {
    const theme = useTheme();
    const { t } = useTranslation();
    const [recipients, setRecipients] = useState<string[]>([]);
    const [days] = useState<string[]>([t("days.monday"), t("days.tuesday"), t("days.wednesday"), t("days.thursday"), t("days.friday"), t("days.saturday"), t("days.sunday")]);
    const [assetFilter, setAssetFilter] = React.useState<string | undefined>(undefined);
    const [recipientFilter, setRecipientFilter] = React.useState<string | undefined>(undefined);
    const { sort } = useContext(FilterSortContext);

    const { getCurrentAccountContacts  } = useAccountApi();
    const { createEventAlert } = useEventAlertsApi({shouldLoadAll:true})

    const { assets , loading: loadingAssets} = useAssetApi({shouldLoadAll:true});
    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [selectedRecipientsRows, setSelectedRecipientsRows] = useState<string[]>([]);
    const [selectedDays, setSelectedDays] = useState<string[]>([]);

    const [selectedEventType, setSelectedEventType] = useState(VehicleEvent.IGNITION_ON);
    const [selectedStartTime, setSelectedStartTime] = useState<Dayjs>(dayjs().startOf('date'));
    const [selectedEndTime, setSelectedEndTime] = useState<Dayjs>(dayjs().endOf('date'));

    const [loadingRecipients, setLoadingRecipients] = useState<boolean>(false);

    const onSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setAssetFilter(undefined);
        } else {
            setAssetFilter(search);
        }
    }
    const onRecipientSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setRecipientFilter(undefined);
        } else {
            setRecipientFilter(search);
        }
    }

    const filterAssets = (assets: Array<Asset>) => {
        let filteredAssets = filterAssetsBySearch(assets, assetFilter);

        switch (sort) {
            case AssetSortOptions.DRIVER_NAME:
                filteredAssets.sort(sortByDriverName);
                break;
            case AssetSortOptions.LAST_REPORTED:
                filteredAssets.sort(sortByLastEventTime);
                break;
            default:
                break;
        }

        return filteredAssets;
    }

    const filterRecipients = (recipients: Array<string>) => {
        let filteredRecipients = recipients

        if (recipientFilter) {
            let lowerCaseFilter = recipientFilter.toLowerCase();
            filteredRecipients = recipients.filter(recipient => {
                if (recipient) {
                    // Check for recipient properties and filter accordingly

                    if (recipient.toLowerCase().includes(lowerCaseFilter)) {
                        return true;
                    }
                }
                return false; // Return false for recipien that don't match the filter
            });
        }

        return filteredRecipients;
    }

    const createEvent = async () => {

        await createEventAlert(new EventAlert({
            id: 1,
            vehicleEventName: selectedEventType,
            startTime: selectedStartTime.format("HH:mm"),
            endTime: selectedEndTime.format("HH:mm"),
            vehicleId: selectedRows[0],
            emailAddress: selectedRecipientsRows,
            mon: selectedDays.includes(days[0]),
            tue: selectedDays.includes(days[1]),
            wed: selectedDays.includes(days[2]),
            thu: selectedDays.includes(days[3]),
            fri: selectedDays.includes(days[4]),
            sat: selectedDays.includes(days[5]),
            sun: selectedDays.includes(days[6]),
        }))
            .then((eventAlerts) => {
                navigate('/alerts/event-alerts')
            })
            .catch((error) => {
                alert(t('alert_creation.failed'))
            })
    }


    const handleCancel = () => {
        navigate('/alerts/event-alerts')
    }

    React.useEffect(() => {

        async function getAccountEmails() {
            setLoadingRecipients(true)
            let contacts = await getCurrentAccountContacts() as AccountContact[];
            let emails = contacts.map(contact => contact.email)
            if (!emails) {
                setRecipients([])
            } else {
                setRecipients(emails)
            }
            setLoadingRecipients(false)
        }

        getAccountEmails()
    }, []);


    return (
        <Box
            component="main"
            sx={{
                // padding: '10px',
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.contentPage.background
            }}
        >
            <Grid container component={Paper} sx={{ padding: '10px', height: '100%', width: '100%' }} elevation={3}>

                {/* Event Type Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{ marginLeft: "20px", marginTop: "10px" }} variant="title">{t("event.event_type")}</Typography>
                        <Typography sx={{ marginLeft: "20px" }} variant="body">{t("event.select_event_type")}</Typography>

                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <EventTypeSelection selectedEventType={selectedEventType} setSelectedEventType={setSelectedEventType} />
                    </Grid>
                </Grid>

                <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />

                {/* Start & End Time Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{ marginLeft: "20px", marginTop: "10px" }} variant="title">{t("event.time_range")}</Typography>
                        <Typography sx={{ marginLeft: "20px" }} variant="body">{t("event.select_start_end_time")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={5} padding="20px">
                        <TimePicker label={t("event.start_time")} value={selectedStartTime} onChange={(newStartTime) => {
                            if (newStartTime != null) {
                                setSelectedStartTime(newStartTime)
                            } else {
                                setSelectedStartTime(dayjs().startOf('date'))
                            }
                        }} />
                    </Grid>
                    <Grid item xs={12} md={4} lg={5} padding="20px">
                        <TimePicker label={t("event.end_time")} value={selectedEndTime} onChange={(newEndTime) => {
                            if (newEndTime != null) {
                                setSelectedEndTime(newEndTime)
                            } else {
                                setSelectedEndTime(dayjs().endOf('date'))
                            }
                        }} />
                    </Grid>
                </Grid>

                <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />

                {/* Days Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{ marginLeft: "20px", marginTop: "10px" }} variant="title">{t("event.event_days")}</Typography>
                        <Typography sx={{ marginLeft: "20px" }} variant="body">{t("event.select_days")}</Typography>

                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Grid>
                            <SelectStringTable
                                multiselect={true}
                                loading={false}
                                header={t("event.day")}
                                values={days}
                                selectedRows={selectedDays}
                                setSelectedRows={setSelectedDays}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />

                {/* Recipients Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{ marginLeft: "20px", marginTop: "10px" }} variant="title">{t("event.recipients")}</Typography>
                        <Typography sx={{ marginLeft: "20px" }} variant="body">{t("event.select_recipients")}</Typography>

                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Box sx={{
                            paddingBottom: '10px'
                        }}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                size="small"
                                label={t("event.recipient_search_box_label")}
                                variant="outlined"
                                color="info"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                value={recipientFilter}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    onRecipientSearchChange(event.target.value);
                                }}
                            />
                        </Box>
                        <Grid>
                            <SelectRecipientTable
                                multiselect={true}
                                loading={loadingRecipients}
                                recipients={filterRecipients(recipients)}
                                selectedRows={selectedRecipientsRows}
                                setSelectedRows={setSelectedRecipientsRows}
                            />
                        </Grid>

                    </Grid>
                </Grid>

                <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />

                {/* Asset Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{ marginLeft: "20px", marginTop: "10px" }}
                            variant="title">{t("event.asset_selection")}</Typography>
                        <Typography sx={{ marginLeft: "20px" }} variant="body">{t("event.asset_selection_message")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <Box sx={{
                            paddingBottom: '10px'
                        }}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                size="small"
                                label={t("event.asset_search_box_label")}
                                variant="outlined"
                                color="info"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                value={assetFilter}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    onSearchChange(event.target.value);
                                }}
                            />
                        </Box>
                        <Grid>
                            <SelectAssetTable
                                multiselect={false}
                                loading={loadingAssets}
                                assets={filterAssets(assets || [])}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                            />
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box
                            bottom="0px"
                            sx={{
                                padding: '10px',
                                display: 'flex', flexDirection: "row", justifyContent: "space-between"
                            }}>
                            <Button
                                variant="contained"
                                size='small'
                                color="secondary"
                                onClick={() => {
                                    handleCancel()
                                }}>
                                {t('button.cancel')}
                            </Button>
                            <Button

                                disabled={selectedRows.length === 0 || selectedRecipientsRows.length === 0 || selectedDays.length === 0}
                                variant="contained"
                                size='small'
                                color="primary"
                                onClick={createEvent}
                            >
                                {t("button.create")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
