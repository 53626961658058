import {Box, Chip, Grid, Radio, Typography} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PlaceIcon from "@mui/icons-material/Place";
import { colors } from "../../themes/AppTheme";
import LockIcon from "@mui/icons-material/Lock";
import BusinessIcon from "@mui/icons-material/Business";
import PanToolIcon from "@mui/icons-material/PanTool";
import WorkIcon from "@mui/icons-material/Work";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import SummarizeIcon from "@mui/icons-material/Summarize";
import SpaIcon from "@mui/icons-material/Spa";
import ShortTextIcon from "@mui/icons-material/ShortText";
import LoadingSpinner from "../../components/loadingspinner/LoadingSpinner";
import useAccountApi from "../../hooks/account/Account";
import {getTranslatedDescription, getTranslatedName, ReportType} from "../../enum/ReportType";

interface ReportTypePanelProps {
    loading: boolean;
    reportTypes: ReportType[];
    reportTypeSelected?: ReportType;
    handleRadioClick: Function;
}

export default function ReportTypePanel(props: ReportTypePanelProps) {
    const { t } = useTranslation();
    const { account } = useAccountApi();

    function isReportTypeAvailableInPackage(reportType: ReportType): boolean {
        if(account === null || account?.featurePackage !== "LITE") {
            return true;
        }

        const liteReportTypes = [
            ReportType.TRAVEL_REPORT,
            ReportType.PRIVATE_REPORT,
            ReportType.BUSINESS_REPORT,
            ReportType.STOP_REPORT,
            ReportType.DETAIL_REPORT,
            ReportType.IDLE_REPORT
        ];

        return liteReportTypes.includes(reportType);
    }

    return (
        <Grid container spacing={2} flexDirection="row">
            {props.loading && <Box sx={{ alignSelf: "center" }}>
                <LoadingSpinner />
            </Box>}
            {!props.loading && props.reportTypes.map((reportType) => (
                <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Box
                        border={2}
                        borderRadius={3}
                        borderColor={"divider"}
                        padding={2}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="space-between"
                        onClick={() => isReportTypeAvailableInPackage(reportType) ? props.handleRadioClick(reportType) : null}
                        sx={{
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <Box>
                            {getIcon(reportType)}
                        </Box>
                        <Box>
                            <Typography align={"center"} variant="title">
                                {getTranslatedName(reportType, t)}
                            </Typography>
                            <Typography align={"center"} variant="body">
                                {getTranslatedDescription(reportType, t)}
                            </Typography>
                        </Box>
                        {isReportTypeAvailableInPackage(reportType) &&
                        <Box sx={{ alignSelf: "center" }}>
                            <Radio
                                disabled={!isReportTypeAvailableInPackage(reportType) && account?.featurePackage === "LITE"}
                                checked={props.reportTypeSelected === reportType}
                            />
                        </Box>}
                        {!isReportTypeAvailableInPackage(reportType) &&
                        <Box sx={{ alignSelf: "center", marginTop: "5px"  }}>
                            <Chip sx={{
                                padding: "5px",
                                height: 'auto',
                                '& .MuiChip-label': {
                                    display: 'block',
                                    whiteSpace: 'normal',
                                },
                            }} color="warning" label={t('report.ram_lite_report_unavailable')} />
                        </Box>}
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
}

function getIcon(reportType: ReportType) {
    switch (reportType) {
        case ReportType.TRAVEL_REPORT:
            return <PlaceIcon sx={{ color: colors.ram_gray }} />;
        case ReportType.PRIVATE_REPORT:
            return <LockIcon sx={{ color: colors.ram_gray }} />;
        case ReportType.BUSINESS_REPORT:
            return <BusinessIcon sx={{ color: colors.ram_gray }} />;
        case ReportType.STOP_REPORT:
            return <PanToolIcon sx={{ color: colors.ram_gray }} />;
        case ReportType.DETAIL_REPORT:
            return <WorkIcon sx={{ color: colors.ram_gray }} />;
        case ReportType.IDLE_REPORT:
            return <ToggleOffIcon sx={{ color: colors.ram_gray }} />;
        case ReportType.SUMMARY_REPORT:
            return <SummarizeIcon sx={{ color: colors.ram_gray }} />;
        case ReportType.GREEN_REPORT:
            return <SpaIcon sx={{ color: colors.ram_gray }} />;
        case ReportType.SUMMARIZED_DETAIL_REPORT:
            return <ShortTextIcon sx={{ color: colors.ram_gray }} />;
        default:
            return <PlaceIcon sx={{ color: colors.ram_gray }} />;
    }
}


