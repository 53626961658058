import {useEffect, useState} from "react";
import usePlatformFetch from "../shared/PlatformFetch";
import PropertiesLoader from "../../properties/PropertiesLoader";
import {Asset, AssetType, EngineHours} from "./dto/Asset";
import {mapToAssetsArray, mapToEventsArray, mapToJourneyObjects, mapToTimelineObjects} from "./AssetMapper";
import moment from "moment";

const properties = PropertiesLoader();

interface FuelAlertsApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export class AssetUpdateRequest {
    alias: string;
    label: string;
    driver: string;
    type: string;
    vehicleClass: string;
    co2Output: number;
    manHour1: number;
    manHour2: number;
    runningCost: number;
    privateCost: number;
    customWorkingHours: boolean;
    weekdayStart: string;
    weekdayEnd: string;
    saturdayStart: string;
    saturdayEnd: string;
    sundayStart: string;
    sundayEnd: string;

    constructor(props: {
        alias: string;
        label: string;
        driver: string;
        type: string;
        vehicleClass: string;
        co2Output: number;
        manHour1: number;
        manHour2: number;
        runningCost: number;
        privateCost: number;
        customWorkingHours: boolean;
        weekdayStart: string;
        weekdayEnd: string;
        saturdayStart: string;
        saturdayEnd: string;
        sundayStart: string;
        sundayEnd: string;
    }) {
        this.alias = props.alias;
        this.label = props.label;
        this.driver = props.driver;
        this.type = props.type;
        this.vehicleClass = props.vehicleClass;
        this.co2Output = props.co2Output;
        this.manHour1 = props.manHour1;
        this.manHour2 = props.manHour2;
        this.runningCost = props.runningCost;
        this.privateCost = props.privateCost;
        this.customWorkingHours = props.customWorkingHours;
        this.weekdayStart = props.weekdayStart;
        this.weekdayEnd = props.weekdayEnd;
        this.saturdayStart = props.saturdayStart;
        this.saturdayEnd = props.saturdayEnd;
        this.sundayStart = props.sundayStart;
        this.sundayEnd = props.sundayEnd;
    }
}


export function useAssetApi(props: FuelAlertsApiOptions = {}) {
    const [assets, setAssets] = useState<Asset[] | null>(null);
    const {platformFetch, checkOk, checkCreated} = usePlatformFetch();
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [hasGenerator, setHasGenerator] = useState(false);
    const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

    const getAllAssets = async (background?: boolean) => {
        return new Promise((resolve, reject) => {
            if (!background) {
                setLoading(true)
            }
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/assets/",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToAssetsArray(json))
                .then(assets => {
                    setAssets(assets)
                    setHasGenerator(assets && hasGeneratorAsset(assets))
                    if (!background) {
                        setLoading(false)
                    }
                    resolve(assets)
                })
                .catch((error) => {
                    console.error("Error getting assets: " + error)
                    setError(error)
                    if (!background) {
                        setLoading(false)
                    }
                    reject(error)
                });
        });
    }

    const getAssetJourneysOnDay = async (assetId: number, date: Date) => {
        return new Promise((resolve, reject) => {
            const journeyDate = moment(date).format(dateFormat)

            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/assets/" + assetId + "/journeys/" + journeyDate,
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToJourneyObjects(json))
                .then(journeys => resolve(journeys))
                .catch((error) => {
                    console.error("Error getting asset journeys: " + error)
                    reject(error)
                });
        });
    }

    const getAssetEventsBetweenDates = async (assetId: number, startDateTime: Date, endDateTime: Date, routeReplay: boolean) => {
        return new Promise((resolve, reject) => {
            const journeyStartDateTime = moment(startDateTime).format(dateFormat)
            const journeyEndDateTime = moment(endDateTime).format(dateFormat)

            const endpoint = routeReplay
                ? `${properties.platformApiEndpoint}tracking/v1/assets/${assetId}/events?startDateTime=${journeyStartDateTime}&endDateTime=${journeyEndDateTime}`
                : `${properties.platformApiEndpoint}tracking/v1/assets/${assetId}/route-replay?startDateTime=${journeyStartDateTime}&endDateTime=${journeyEndDateTime}`;

            platformFetch(endpoint, 'get')
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToEventsArray(json))
                .then(events => resolve(events))
                .catch((error) => {
                    console.error("Error getting asset events: " + error);
                    reject(error);
                });
        });
    }

    const getAssetTimelineBlocksForDate = async (assetId: number, startDateTime: Date, background?: boolean) => {
        return new Promise((resolve, reject) => {
            if (!background) {
                setLoading(true)
            }
            const timelineStartDateTime = moment(startDateTime).format(dateFormat)

            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/assets/" + assetId + "/timelines/" + timelineStartDateTime,
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(json => mapToTimelineObjects(json))
                .then(timelineBlocks => {
                    if (!background) {
                        setLoading(false)
                    }
                    resolve(timelineBlocks)
                })
                .catch((error) => {
                    console.error("Error getting asset timeline: " + error)
                    if (!background) {
                        setLoading(false)
                    }
                    reject(error)
                });
        });
    }

    const updateAsset = async (assetUpdateRequest: AssetUpdateRequest, assetId: string) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/assets/${assetId}`,
                'put',
                JSON.stringify(assetUpdateRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(asset => resolve(asset))
                .catch((error) => {
                    console.error("Error updating asset: " + error)
                    reject(error)
                });
        });
    }

    function hasGeneratorAsset(assets: Asset[]): boolean {
        return assets.some(asset => asset.type === AssetType.GENERATOR);
    }

    function filterGenerator(assets: Asset[]) {
        return assets.filter(asset => asset.type === AssetType.GENERATOR)
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllAssets(false)
                .then(() => {
                    if (props.shouldPoll) {
                        const interval = setInterval(() => getAllAssets(true), props.pollFrequency || 30000);
                        return () => clearInterval(interval);
                    }
                });
        }
    }, []);

    return { loading, error, assets, setAssets, hasGenerator, filterGenerator, getAllAssets, getAssetJourneysOnDay, getAssetEventsBetweenDates, getAssetTimelineBlocksForDate, updateAsset };
}
