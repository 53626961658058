import usePlatformFetch from "../shared/PlatformFetch";
import PropertiesLoader from "../../properties/PropertiesLoader";
import {useEffect, useState} from "react";
import {ScheduledReport} from "./dto/ScheduledReport";
import {ScheduledReportRequest} from "./dto/ScheduledReportRequest";

const properties = PropertiesLoader();

interface ScheduledReportsApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}


export function useScheduledReportsApi(props: ScheduledReportsApiOptions = {}) {
    const {platformFetch, checkOk} = usePlatformFetch();
    const [scheduledReports, setScheduledReports] = useState<ScheduledReport[]>([]);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getScheduledReports = async () => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "scheduled-reports/v1/scheduled-reports",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(scheduledReport => {
                    setScheduledReports(scheduledReport)
                    setLoading(false)
                    resolve(scheduledReport)
                })
                .catch((error) => {
                    console.error("Failed to get scheduled reports : " + error)
                    reject(error)
                });
        })
    }

    const deleteScheduledReport = async (scheduledReportId: number) => {
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "scheduled-reports/v1/scheduled-reports/" + scheduledReportId,
                'delete')
                .then(checkOk)
                .then(() => {
                    resolve()
                })
                .catch((error) => {
                    console.error("Error deleting scheduled report : " + error)
                    setError(error)
                    reject(error)
                });
        });
    }

    const createScheduledReport = async (scheduledReportRequest: ScheduledReportRequest) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + "scheduled-reports/v1/scheduled-reports",
                'post',
                JSON.stringify(scheduledReportRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error) => {
                    console.error("Error creating geofence alerts: " + error)
                    reject(error)
                });
        });
    }

    const updateScheduledReport = async (scheduledReportRequest: ScheduledReportRequest) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `scheduled-reports/v1/scheduled-reports/${scheduledReportRequest.id}`,
                'put',
                JSON.stringify(scheduledReportRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error) => {
                    console.error("Error creating geofence alerts: " + error)
                    reject(error)
                });
        });
    }
    useEffect(() => {
        if (props.shouldLoadAll) {
            getScheduledReports();
            if (props.shouldPoll) {
                const interval = setInterval(getScheduledReports, props.pollFrequency ?? 30000);
                return () => clearInterval(interval);
            }
        }
    }, []);

    return {loading, error, scheduledReports, getScheduledReports, deleteScheduledReport, createScheduledReport, updateScheduledReport}
}
