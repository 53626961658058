import {AlertColor, Divider} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import React, {useContext, useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import {DateRangeSelector} from "./shared/DateRangeSelector";
import {FileFormatSelector} from "./shared/FileFormatSelector";
import {FileFormat} from "../../enum/FileFormat";
import {AssetSelector} from "./shared/AssetSelector";
import {
    ReportStyle,
    ReportStyleSelector,
    VEHICLE_REPORT_GROUPS,
    VEHICLE_SINGLE_REPORTS,
    VEHICLE_SUMMARY_REPORTS
} from "./shared/ReportStyleSelector";
import {ReportTypeSelector} from "./shared/ReportTypeSelector";
import {ReportButtonBar} from "./shared/ReportButtonBar";
import {ReportRequest} from "../../hooks/reports/dto/ReportRequest";
import {getCountry} from "../../enum/Country";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
import utc from 'dayjs/plugin/utc';
import {SummariseBySelector} from "./shared/SummariseBySelector";
import {SummariseIncludeSelector} from "./shared/SummariseIncludeSelector";
import {ReportEmailSelector} from "./shared/ReportEmailSelector";
import ReactGA from "react-ga4";
import {ReportType} from "../../enum/ReportType";
import { ReportPreferencesContext } from "../../providers/ReportPreferencesProvider";

interface VehicleReportsPageProps {
    requestReport: (request: ReportRequest) => void;
    showReportMessage: (message: string, severity: AlertColor) => void;
}

export function VehicleReportsPage(props: VehicleReportsPageProps) {
    const {languageCode} = useContext(UserPreferencesContext)
    const { vehicleReportPreferences } = useContext(ReportPreferencesContext);
    const { selectedFromDate,setSelectedFromDate,
        selectedToDate,setSelectedToDate,
        selectedReportGroup,setSelectedReportGroup,
        selectedFileFormat,setSelectedFileFormat,
        selectedAssets,setSelectedAssets,
        selectedReportType,setSelectedReportType,
        selectedByWeek,setSelectedByWeek,
        selectedByMonth,setSelectedByMonth,
        selectedByVehicle,setSelectedByVehicle,
        selectedByDriver,setSelectedByDriver,
        selectedIncludeFullReport,setSelectedIncludeFullReport,
        selectedIncludeSummaryTotals,setSelectedIncludeSummaryTotals,
        selectedEmail,setEmail, } = vehicleReportPreferences;

    // // Import and enable the UTC plugin for dayjs
    dayjs.extend(utc);

    function handleClear() {
        setSelectedFromDate(dayjs().startOf('date').utc(true))
        setSelectedToDate(dayjs().startOf('date').utc(true))
        setSelectedReportGroup(VEHICLE_SINGLE_REPORTS)
        setSelectedFileFormat(FileFormat.PDF)
        setSelectedReportType(VEHICLE_SINGLE_REPORTS.reportTypes[0])
        setSelectedAssets([])
    }

    async function createReport() {
        ReactGA.event("RT2-Reports-" + selectedReportGroup.name + "-" + selectedReportType + "-" + selectedFileFormat);


        let forSummaryReport: boolean = false;

        if (selectedReportGroup === VEHICLE_SUMMARY_REPORTS) {
            forSummaryReport = true;
        }

        let request: ReportRequest = {
            reportType: selectedReportType,
            from: selectedFromDate.utc(true).toDate(),
            to: selectedToDate.utc(true).toDate(),
            vehicleIds: selectedAssets,
            country: getCountry(languageCode),
            emails: [selectedEmail],
            reportFormat: selectedFileFormat,
            byWeek: selectedByWeek,
            byMonth: selectedByMonth,
            byVehicle: selectedByVehicle,
            byDriver: selectedByDriver,
            includeFullReport: selectedIncludeFullReport,
            includeSummaryTotals: selectedIncludeSummaryTotals,
            forSummaryReport: forSummaryReport
        };

        props.requestReport(request);
    }

    useEffect(() => {
        setSelectedReportType(selectedReportGroup.reportTypes[0]);
    }, [selectedReportGroup]);

    return <Paper elevation={3} style={{display: 'flex', flexDirection: 'column', height: '100%'}}>

        <DateRangeSelector
            selectedFromDate={selectedFromDate}
            setSelectedFromDate={setSelectedFromDate}
            selectedToDate={selectedToDate}
            setSelectedToDate={setSelectedToDate}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <ReportStyleSelector
            availableReportGroups={VEHICLE_REPORT_GROUPS}
            selectedReportGroup={selectedReportGroup}
            setSelectedReportGroup={setSelectedReportGroup}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        {selectedReportGroup === VEHICLE_SUMMARY_REPORTS && (
            <>
                {/* Summarise By Selection */}

                <SummariseBySelector
                    selectedByWeek={selectedByWeek}
                    setSelectedByWeek={setSelectedByWeek}
                    selectedByMonth={selectedByMonth}
                    setSelectedByMonth={setSelectedByMonth}
                    selectedByVehicle={selectedByVehicle}
                    setSelectedByVehicle={setSelectedByVehicle}
                    selectedByDriver={selectedByDriver}
                    setSelectedByDriver={setSelectedByDriver}
                />


                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Include Selection */}

                <SummariseIncludeSelector
                    selectedIncludeFullReport={selectedIncludeFullReport}
                    setSelectedIncludeFullReport={setSelectedIncludeFullReport}
                    selectedIncludeSummaryTotals={selectedIncludeSummaryTotals}
                    setSelectedIncludeSummaryTotals={setSelectedIncludeSummaryTotals}
                />

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* E-mail */}

                <ReportEmailSelector
                    email={selectedEmail}
                    setEmail={setEmail}
                />

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

            </>
        )}

        <FileFormatSelector
            selectedFileFormat={selectedFileFormat}
            setSelectedFileFormat={setSelectedFileFormat}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <ReportTypeSelector
            reportTypes={selectedReportGroup.reportTypes}
            selectedReportType={selectedReportType}
            setSelectedReportType={setSelectedReportType}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <AssetSelector
            singleReport={!selectedReportGroup.multiAsset}
            filterGenerator={false}
            selectedAssets={selectedAssets}
            setSelectedAssets={setSelectedAssets}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <ReportButtonBar
            handleClearSelection={handleClear}
            createDisabled={selectedAssets.length < 1}
            handleCreateReport={createReport}
        />
    </Paper>
}
