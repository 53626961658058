import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc"; 
import { createContext, useMemo, useState } from "react";
import { FileFormat } from "../enum/FileFormat";
import { ReportType } from "../enum/ReportType";
import { ReportPage } from "../pages/reports/ReportsSecondaryNavigation";
import { GENERATOR_SINGLE_REPORTS, ReportStyle, VEHICLE_SINGLE_REPORTS } from "../pages/reports/shared/ReportStyleSelector";

dayjs.extend(utc);

interface VehicleReportsPagePreferences {
    // Properties for VehicleReportsPage
    selectedFromDate: Dayjs;
    setSelectedFromDate: React.Dispatch<React.SetStateAction<Dayjs>>;
    selectedToDate: Dayjs;
    setSelectedToDate: React.Dispatch<React.SetStateAction<Dayjs>>;
    selectedReportGroup: ReportStyle;
    setSelectedReportGroup: React.Dispatch<React.SetStateAction<ReportStyle>>;
    selectedFileFormat: FileFormat;
    setSelectedFileFormat: React.Dispatch<React.SetStateAction<FileFormat>>;
    selectedAssets: number[],
    setSelectedAssets: React.Dispatch<React.SetStateAction<number[]>>;
    selectedReportType: ReportType;
    setSelectedReportType: React.Dispatch<React.SetStateAction<ReportType>>;
    selectedByWeek: boolean;
    setSelectedByWeek: React.Dispatch<React.SetStateAction<boolean>>;
    selectedByMonth: boolean;
    setSelectedByMonth: React.Dispatch<React.SetStateAction<boolean>>;
    selectedByVehicle: boolean;
    setSelectedByVehicle: React.Dispatch<React.SetStateAction<boolean>>;
    selectedByDriver: boolean;
    setSelectedByDriver: React.Dispatch<React.SetStateAction<boolean>>;
    selectedIncludeFullReport: boolean;
    setSelectedIncludeFullReport: React.Dispatch<React.SetStateAction<boolean>>;
    selectedIncludeSummaryTotals: boolean;
    setSelectedIncludeSummaryTotals: React.Dispatch<React.SetStateAction<boolean>>;
    selectedEmail: string,
    setEmail: React.Dispatch<React.SetStateAction<string>>;
}

interface GeneratorReportsPagePreferences {
    // Properties for GeneratorReportsPage
    selectedFromDate: Dayjs;
    setSelectedFromDate: React.Dispatch<React.SetStateAction<Dayjs>>;
    selectedToDate: Dayjs;
    setSelectedToDate: React.Dispatch<React.SetStateAction<Dayjs>>;
    selectedReportGroup: ReportStyle;
    setSelectedReportGroup: React.Dispatch<React.SetStateAction<ReportStyle>>;
    selectedFileFormat: FileFormat;
    setSelectedFileFormat: React.Dispatch<React.SetStateAction<FileFormat>>;
    selectedAssets: number[],
    setSelectedAssets: React.Dispatch<React.SetStateAction<number[]>>;
    selectedReportType: ReportType;
    setSelectedReportType: React.Dispatch<React.SetStateAction<ReportType>>;
}

interface ReportPreferencesContextProps {
    // Properties for ReportsPage
    selectedReportPage: ReportPage;
    setSelectedReportPage: React.Dispatch<React.SetStateAction<ReportPage>>;
    vehicleReportPreferences: VehicleReportsPagePreferences;
    generatorPreferences: GeneratorReportsPagePreferences;
}

export const ReportPreferencesContext = createContext<ReportPreferencesContextProps>({
    // Properties for ReportsPage
    selectedReportPage: ReportPage.VEHICLES,
    setSelectedReportPage: () => {},
    // Properties for VehicleReportsPage
    vehicleReportPreferences: {
        selectedFromDate: dayjs().startOf('date').utc(true),
        setSelectedFromDate: () => {},
        selectedToDate: dayjs().startOf('date').utc(true),
        setSelectedToDate: () => {},
        selectedReportGroup: VEHICLE_SINGLE_REPORTS,
        setSelectedReportGroup: () => {},
        selectedFileFormat: FileFormat.PDF,
        setSelectedFileFormat: () => {},
        selectedAssets:[],
        setSelectedAssets: () => {},
        selectedReportType: VEHICLE_SINGLE_REPORTS.reportTypes[0],
        setSelectedReportType: () => {},
        selectedByWeek: false,
        setSelectedByWeek:  () => {},
        selectedByMonth: false,
        setSelectedByMonth:  () => {},
        selectedByVehicle: false,
        setSelectedByVehicle:  () => {},
        selectedByDriver: false,
        setSelectedByDriver:  () => {},
        selectedIncludeFullReport: false,
        setSelectedIncludeFullReport:  () => {},
        selectedIncludeSummaryTotals: false,
        setSelectedIncludeSummaryTotals:  () => {},
        selectedEmail : "",
        setEmail:  () => {},
    },
    // Properties for GeneratorReportsPage
    generatorPreferences: {
        selectedFromDate: dayjs().startOf('date').utc(true),
        setSelectedFromDate: () => {},
        selectedToDate: dayjs().startOf('date').utc(true),
        setSelectedToDate: () => {},
        selectedReportGroup: GENERATOR_SINGLE_REPORTS,
        setSelectedReportGroup: () => {},
        selectedFileFormat: FileFormat.PDF,
        setSelectedFileFormat: () => {},
        selectedAssets:[],
        setSelectedAssets: () => {},
        selectedReportType: GENERATOR_SINGLE_REPORTS.reportTypes[0],
        setSelectedReportType: () => {},
    }
});

interface Props {
    children: React.ReactNode;
}

export const ReportProvider = ({ children }: Props) => {
    // Properties for ReportsPage
    const [selectedReportPage, setSelectedReportPage] = useState<ReportPage>(ReportPage.VEHICLES);

    // Properties for VehicleReportsPage
    const [selectedFromDate, setSelectedFromDate] = useState<Dayjs>(dayjs().startOf('date').utc(true));
    const [selectedToDate, setSelectedToDate] = useState<Dayjs>(dayjs().startOf('date').utc(true));
    const [selectedReportGroup, setSelectedReportGroup] = useState<ReportStyle>(VEHICLE_SINGLE_REPORTS);
    const [selectedFileFormat, setSelectedFileFormat] = useState(FileFormat.PDF);
    const [selectedAssets, setSelectedAssets] = useState<number[]>([]);
   const [selectedReportType, setSelectedReportType] = useState<ReportType>(VEHICLE_SINGLE_REPORTS.reportTypes[0]);
    const [selectedByWeek, setSelectedByWeek] = useState(false);
    const [selectedByMonth, setSelectedByMonth] = useState(false);
    const [selectedByVehicle, setSelectedByVehicle] = useState(false);
    const [selectedByDriver, setSelectedByDriver] = useState(false);
    const [selectedIncludeFullReport, setSelectedIncludeFullReport] = useState(false);
    const [selectedIncludeSummaryTotals, setSelectedIncludeSummaryTotals] = useState(false);
    const [selectedEmail, setEmail] = useState<string>('')
    // Properties for GeneratorReportsPage
    const [selectedFromDateGenerator, setSelectedFromDateGenerator] = useState<Dayjs>(dayjs().startOf('date').utc(true));
    const [selectedToDateGenerator, setSelectedToDateGenerator] = useState<Dayjs>(dayjs().startOf('date').utc(true));
    const [selectedReportGroupGenerator, setSelectedReportGroupGenerator] = useState<ReportStyle>(GENERATOR_SINGLE_REPORTS);
    const [selectedFileFormatGenerator, setSelectedFileFormatGenerator] = useState(FileFormat.PDF);
    const [selectedAssetsGenerator, setSelectedAssetsGenerator] = useState<number[]>([]);
    const [selectedReportTypeGenerator, setSelectedReportTypeGenerator] = useState<ReportType>(GENERATOR_SINGLE_REPORTS.reportTypes[0]);


    const contextValue: ReportPreferencesContextProps = useMemo(
        () => ({
            // Properties for ReportsPage
            selectedReportPage, setSelectedReportPage,
            // Properties for VehicleReportsPage
            vehicleReportPreferences: {
                selectedFromDate: selectedFromDate,
                setSelectedFromDate: setSelectedFromDate,
                selectedToDate: selectedToDate,
                setSelectedToDate: setSelectedToDate,
                selectedReportGroup: selectedReportGroup,
                setSelectedReportGroup: setSelectedReportGroup,
                selectedFileFormat: selectedFileFormat,
                setSelectedFileFormat: setSelectedFileFormat,
                selectedAssets:selectedAssets,
                setSelectedAssets: setSelectedAssets,
                selectedReportType: selectedReportType,
                setSelectedReportType: setSelectedReportType,
                selectedByWeek: selectedByWeek,
                setSelectedByWeek:  setSelectedByWeek,
                selectedByMonth: selectedByMonth,
                setSelectedByMonth:  setSelectedByMonth,
                selectedByVehicle: selectedByVehicle,
                setSelectedByVehicle:  setSelectedByVehicle,
                selectedByDriver: selectedByDriver,
                setSelectedByDriver:  setSelectedByDriver,
                selectedIncludeFullReport: selectedIncludeFullReport,
                setSelectedIncludeFullReport: setSelectedIncludeFullReport,
                selectedIncludeSummaryTotals: selectedIncludeSummaryTotals,
                setSelectedIncludeSummaryTotals:setSelectedIncludeSummaryTotals,
                selectedEmail : selectedEmail,
                setEmail: setEmail,
            },
            generatorPreferences: {
                selectedFromDate: selectedFromDateGenerator,
                setSelectedFromDate: setSelectedFromDateGenerator,
                selectedToDate: selectedToDateGenerator,
                setSelectedToDate: setSelectedToDateGenerator,
                selectedReportGroup: selectedReportGroupGenerator,
                setSelectedReportGroup: setSelectedReportGroupGenerator,
                selectedFileFormat: selectedFileFormatGenerator,
                setSelectedFileFormat: setSelectedFileFormatGenerator,
                selectedAssets:selectedAssetsGenerator,
                setSelectedAssets: setSelectedAssetsGenerator,
                selectedReportType: selectedReportTypeGenerator,
                setSelectedReportType: setSelectedReportTypeGenerator,
            },

        }),
        [selectedReportPage, setSelectedReportPage, selectedFromDate, setSelectedFromDate, selectedToDate, setSelectedToDate, selectedReportGroup, setSelectedReportGroup, 
            selectedFileFormat, setSelectedFileFormat, selectedAssets, setSelectedAssets, selectedReportType, setSelectedReportType,
            selectedByWeek, setSelectedByWeek, selectedByMonth, setSelectedByMonth, selectedByVehicle, setSelectedByVehicle,
            selectedByDriver, setSelectedByDriver, selectedIncludeFullReport, setSelectedIncludeFullReport, 
            selectedIncludeSummaryTotals, setSelectedIncludeSummaryTotals, selectedEmail, setEmail,
            selectedFromDateGenerator, setSelectedFromDateGenerator, selectedToDateGenerator, setSelectedToDateGenerator,
            selectedReportGroupGenerator, setSelectedReportGroupGenerator, selectedFileFormatGenerator, setSelectedFileFormatGenerator,
            selectedFileFormatGenerator, setSelectedFileFormatGenerator, selectedAssetsGenerator, setSelectedAssetsGenerator,
            selectedReportTypeGenerator, setSelectedReportTypeGenerator]
    );

    return (
        <ReportPreferencesContext.Provider value={contextValue}>
            {children}
        </ReportPreferencesContext.Provider>
    );
};
