import {Box, Paper, Tooltip} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Table, TableCell, TableRow} from "../../../components/table/core/Table";
import {RowDeleteButton, RowEditButton} from "../../../components/table/core/TableButtons";
import {useScheduledReportsApi} from "../../../hooks/scheduledReport/ScheduledReports";
import {ScheduledReport} from "../../../hooks/scheduledReport/dto/ScheduledReport";
import {getTranslatedName} from "../../../enum/ReportType";
import {useNavigate} from 'react-router-dom';
import ConfirmationDialog from '../../../components/dialog/ConfirmationDialog';
import FilterBar from "../../../components/filterbar/FilterBar";

interface ScheduledVehicleReportsPageProps {
}

export default function ScheduledVehicleReportsPage(props: ScheduledVehicleReportsPageProps) {

    const {t} = useTranslation();

    const {scheduledReports, getScheduledReports, loading} = useScheduledReportsApi({shouldLoadAll: true});
    const [searchInput, setSearchInput] = React.useState<string>("");
    const navigate = useNavigate();

    function getFilteredScheduledReports() {
        if (scheduledReports === null) {
            return [];
        } else if (searchInput === undefined || searchInput === "") {
            return scheduledReports || [];
        } else {
            const lowerCaseFilter = searchInput.toLowerCase();
            return scheduledReports.filter(scheduledReport => scheduledReport.vehicles.some(vehicle => vehicle.toLowerCase().includes(lowerCaseFilter)) ||
                scheduledReport.recipients.some(recipient => recipient.toLowerCase().includes(lowerCaseFilter)) ||
                scheduledReport.name.toLowerCase().includes(lowerCaseFilter)
            );
        }
    }

    const columns = [
        { id:'name', content: t('report_page.scheduled_report_table.name')},
        { id:'assets', content: t('report_page.scheduled_report_table.assets')},
        { id:'recipients', content: t('report_page.scheduled_report_table.recipients')},
        { id:'type', content: t('report_page.scheduled_report_table.report_type')},
        { id:'format', content: t('report_page.scheduled_report_table.report_format')},
        { id:'period', content: t('report_page.scheduled_report_table.period')},
        { id:'delivery', content: t('report_page.scheduled_report_table.delivery')},
        { id:'time', content: t('report_page.scheduled_report_table.time_range')},
        { id:'action', content: t('report_page.scheduled_report_table.action')}
    ];

    const rows = getFilteredScheduledReports().map((scheduledReport) => {
        return <ScheduledReportRow key={scheduledReport.id} scheduledReport={scheduledReport} updateTrigger={getScheduledReports}/>
    })

    return (
        <Paper elevation={3} style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "space-between",
                margin: '30px',
                marginTop: '10px',
                height: '100%',
            }}>
                <FilterBar onSearchChange={setSearchInput} filter={searchInput} label={'Search for asset, recipients...'} />

                <Table
                    id="scheduled-reports"
                    label="Scheduled Reports"
                    columns={columns}
                    loading={loading}
                    rows={rows}
                    createAction={() => {
                        navigate('/scheduled-reports-editor')
                    }}
                    emptyLabel={t('report_page.scheduled_report_table.no_scheduled_report') as string}
                />
            </Box>
        </Paper>
    );
};

function ScheduledReportRow(props: { scheduledReport: ScheduledReport, updateTrigger: () => void }) {
    const {t} = useTranslation();

    const navigate = useNavigate()

    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)
    const [selectedScheduledReportId, setSelectedScheduledReportId] = React.useState<number | null>(null);

    const scheduledReportApi = useScheduledReportsApi({shouldPoll: false});

    async function handleDelete() {
        if (selectedScheduledReportId !== null) {
            try {
                await scheduledReportApi.deleteScheduledReport(selectedScheduledReportId);
                setShowDeleteDialog(false);
                props.updateTrigger();
            } catch {
                setShowDeleteDialog(false);
                alert(t('report_page.scheduled_report_table.delete_alert_report_not_found'));
            }
        }
    }

    return <TableRow id={props.scheduledReport.id.toString()}>
        <TableCell>{props.scheduledReport.name}</TableCell>
        <TableCell>
            <>
                {props.scheduledReport.vehicles.length > 3 ?
                    <div>{props.scheduledReport.vehicles.length + " " + t('report_page.scheduled_report_table.assets')}</div> :
                    props.scheduledReport.vehicles.map(vehicle => (
                        <div
                            key={props.scheduledReport.id.toString() + "_vehicles_" + vehicle}
                            id={props.scheduledReport.id.toString() + "_vehicles_" + vehicle}>
                            {vehicle} <br/>
                        </div>
                    ))
                }
            </>
        </TableCell>
        <TableCell>
            <>
                {props.scheduledReport.recipients.length > 3 ? (
                        <Tooltip title={props.scheduledReport.recipients.join(', ')} arrow>
                            <div>{props.scheduledReport.recipients.length + ' ' + t('report_page.scheduled_report_table.recipients')}</div>
                        </Tooltip>
                    ) :
                    props.scheduledReport.recipients.map(recipient => (
                        <div
                            key={props.scheduledReport.id.toString() + "_recipients_" + recipient}
                            id={props.scheduledReport.id.toString() + "_recipients_" + recipient}>
                            {recipient} <br/>
                        </div>
                    ))
                }
            </>
        </TableCell>
        <TableCell>
            {getTranslatedName(props.scheduledReport.reportType, t)}
        </TableCell>
        <TableCell>
            {props.scheduledReport.reportFormat}
        </TableCell>
        <TableCell>
            {props.scheduledReport.period}
        </TableCell>
        <TableCell>
            {props.scheduledReport.deliveryDay}
        </TableCell>
        <TableCell>
            {props.scheduledReport.daysCovered.toString()}
        </TableCell>

        <TableCell>
            <RowEditButton action={() => {
                const selectedScheduledReport = props.scheduledReport;
                navigate('/scheduled-reports-editor', {state: {selectedScheduledReport}});
            }}/>
            <RowDeleteButton action={() => {
                setSelectedScheduledReportId(props.scheduledReport.id);
                setShowDeleteDialog(true);
            }}/>
            <ConfirmationDialog open={showDeleteDialog}
                                title={t('report_page.scheduled_report_table.delete_dialog_title')}
                                description={t('report_page.scheduled_report_table.delete_dialog_description')}
                                confirmText={t('button.delete')} cancelText={t('button.cancel')}
                                onConfirm={handleDelete}
                                onCancel={() => setShowDeleteDialog(false)}/>
        </TableCell>
    </TableRow>
}

