import {AlertColor, Divider} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import React, {useContext, useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import {DateRangeSelector} from "../shared/DateRangeSelector";
import {FileFormatSelector} from "../shared/FileFormatSelector";
import {FileFormat} from "../../../enum/FileFormat";
import {AssetSelector} from "../shared/AssetSelector";
import {
    GENERATOR_REPORT_GROUPS,
    GENERATOR_SINGLE_REPORTS,
    ReportStyle,
    ReportStyleSelector
} from "../shared/ReportStyleSelector";
import {ReportTypeSelector} from "../shared/ReportTypeSelector";
import {ReportButtonBar} from "../shared/ReportButtonBar";
import {ReportRequest} from "../../../hooks/reports/dto/ReportRequest";
import {getCountry} from "../../../enum/Country";
import {UserPreferencesContext} from "../../../providers/UserPreferencesProvider";
import utc from 'dayjs/plugin/utc';
import ReactGA from "react-ga4";
import {ReportType} from "../../../enum/ReportType";
import { ReportPreferencesContext } from "../../../providers/ReportPreferencesProvider";

interface GeneratorReportsPageProps {
    requestReport: (request: ReportRequest) => void;
    showReportMessage: (message: string, severity: AlertColor) => void;
}

export function GeneratorReportsPage(props: GeneratorReportsPageProps) {
    const {languageCode} = useContext(UserPreferencesContext)
    const { generatorPreferences } = useContext(ReportPreferencesContext);
    const { selectedFromDate, setSelectedFromDate, selectedToDate, setSelectedToDate,
        selectedReportGroup, setSelectedReportGroup, selectedFileFormat, setSelectedFileFormat,
        selectedAssets, setSelectedAssets, selectedReportType, setSelectedReportType} = generatorPreferences;

    // // Import and enable the UTC plugin for dayjs
    dayjs.extend(utc);

    function handleClear() {
        setSelectedFromDate(dayjs().startOf('date').utc(true))
        setSelectedToDate(dayjs().startOf('date').utc(true))
        setSelectedReportGroup(GENERATOR_SINGLE_REPORTS)
        setSelectedFileFormat(FileFormat.PDF)
        setSelectedReportType(GENERATOR_SINGLE_REPORTS.reportTypes[0])
        setSelectedAssets([])
    }

    async function createReport() {
        ReactGA.event("RT2-Reports-Generator" + selectedReportGroup.name + "-" + selectedReportType + "-" + selectedFileFormat);

        let request: ReportRequest = {
            reportType: selectedReportType,
            from: selectedFromDate.utc(true).toDate(),
            to: selectedToDate.utc(true).toDate(),
            vehicleIds: selectedAssets,
            country: getCountry(languageCode),
            emails: [],
            reportFormat: selectedFileFormat,
            byWeek: false,
            byMonth: false,
            byVehicle: false,
            byDriver: false,
            includeFullReport: false,
            includeSummaryTotals: false,
            forSummaryReport: false
        };

        props.requestReport(request);
    }

    useEffect(() => {
        setSelectedReportType(selectedReportGroup.reportTypes[0]);
    }, [selectedReportGroup]);

    return <Paper elevation={3} style={{display: 'flex', flexDirection: 'column', height: '100%'}}>

        <DateRangeSelector
            selectedFromDate={selectedFromDate}
            setSelectedFromDate={setSelectedFromDate}
            selectedToDate={selectedToDate}
            setSelectedToDate={setSelectedToDate}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <ReportStyleSelector
            availableReportGroups={GENERATOR_REPORT_GROUPS}
            selectedReportGroup={selectedReportGroup}
            setSelectedReportGroup={setSelectedReportGroup}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <FileFormatSelector
            selectedFileFormat={selectedFileFormat}
            setSelectedFileFormat={setSelectedFileFormat}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <ReportTypeSelector
            reportTypes={selectedReportGroup.reportTypes}
            selectedReportType={selectedReportType}
            setSelectedReportType={setSelectedReportType}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <AssetSelector
            singleReport={!selectedReportGroup.multiAsset}
            filterGenerator={true}
            selectedAssets={selectedAssets}
            setSelectedAssets={setSelectedAssets}
        />

        <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

        <ReportButtonBar
            handleClearSelection={handleClear}
            createDisabled={selectedAssets.length < 1}
            handleCreateReport={createReport}
        />
    </Paper>
}
