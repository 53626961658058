import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { VehicleEvent } from "../../../../enum/VehicleEvent";
import { colors } from "../../../../themes/AppTheme";


interface EventTypeSelectionProps {
    selectedEventType: string;
    setSelectedEventType: (selectedEventType: VehicleEvent) => void;
}

export default function EventTypeSelection(props: EventTypeSelectionProps) {
    const { t } = useTranslation();

    function handleEventTypeChange(event: SelectChangeEvent<any>) {
        props.setSelectedEventType(event.target.value);
    }

    return (
        <Select
            id="file_format"
            size="small"
            value={props.selectedEventType}
            color="info"
            style={{ height: '100%', fontSize: '12px', color: colors.ram_purple }}
            onChange={handleEventTypeChange}
            fullWidth
        >
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.IGNITION_ON}>{t('vehicle_event.ignition_on')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.IGNITION_OFF}>{t('vehicle_event.ignition_off')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.FIRST_IGNITION_OF_DAY}>{t('vehicle_event.first_ignition_of_day')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.EXT_POWER_LOST}>{t('vehicle_event.ext_power_lost')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.INT_BAT_LOW}>{t('vehicle_event.int_bat_low')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.ZONE_IN}>{t('vehicle_event.zone_in')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.ZONE_OUT}>{t('vehicle_event.zone_out')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.EXTENDED_IDLE_1}>{t('vehicle_event.extended_idle_1')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.EXTENDED_IDLE_2}>{t('vehicle_event.extended_idle_2')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.FATIGUE}>{t('vehicle_event.fatigue')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.EXTENDED_STOP}>{t('vehicle_event.extended_stop')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.PRIVATE_MILEAGE}>{t('vehicle_event.private_mileage')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.PRIVATE_MILEAGE_START}>{t('vehicle_event.private_mileage_start')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.TOW_ALERT}>{t('vehicle_event.tow_alert')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.IMPACT_DETECTED}>{t('vehicle_event.impact_detected')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.INPUT_2_HIGH}>{t('vehicle_event.input_2_high')}</MenuItem>
            <MenuItem key="event-type" style={{ height: 25, fontSize: '12px' }} value={VehicleEvent.OVER_SPEED}>{t('vehicle_event.over_speed')}</MenuItem>

        </Select>
    );
}