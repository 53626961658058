import { MouseEventHandler, ReactComponentElement } from "react";

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

interface DrawListItemProps {
  itemName: string;
  icon: ReactComponentElement<any>;
  text: string;
  to: string;
  onClick: MouseEventHandler<HTMLLIElement>;
  drawOpen: boolean;
  selected: boolean;
}

function DrawListItem(props: DrawListItemProps) {

  const theme = useTheme();

  const item = (<ListItem

    key={props.itemName}
    onClick={props.onClick}
    disablePadding
    sx={{
      display: "block",
      backgroundColor: props.selected ? theme.palette.drawer.backgroundSelected : theme.palette.drawer.background,
      borderRadius: '5px',
      margin: '2px',
      "&:hover": {
        backgroundColor: props.selected ? theme.palette.drawer.backgroundSelected : theme.palette.drawer.backgroundHover,
      },
    }}
  >
    <ListItemButton
      component={Link}
      to={props.to}
      sx={{
        minHeight: 34,
        justifyContent: props.drawOpen ? "initial" : "center",
        px: 2,
        color: props.selected ? theme.palette.drawer.textSelected : theme.palette.drawer.text,
        "&:hover": {
          color: props.selected ? theme.palette.drawer.textSelected : theme.palette.drawer.textHover,
        },
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: props.drawOpen ? 3 : "auto",
          justifyContent: "center",
          color: props.selected ? theme.palette.drawer.textSelected : theme.palette.drawer.text,
          "&:hover": {
            color: props.selected ? theme.palette.drawer.textSelected : theme.palette.drawer.textHover,
          },
        }}
      >
        {props.icon}
      </ListItemIcon>
      <ListItemText
        primary={props.text}
        sx={{ opacity: props.drawOpen ? 1 : 0 }}
      />
    </ListItemButton>
  </ListItem>)

  if (!props.drawOpen) {
    return (
      <Tooltip title={props.text} placement="right">
        {item}
      </Tooltip>
  );
  } else {
    return item;
  }
}

export default DrawListItem;
